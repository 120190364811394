// extracted by mini-css-extract-plugin
export var bulletContainer = "feature-card-switcher-module--bulletContainer---YEdb";
export var bulletItem = "feature-card-switcher-module--bulletItem--9DLWZ";
export var check = "feature-card-switcher-module--check--2yT0w";
export var container = "feature-card-switcher-module--container--gxS78";
export var contentContainer = "feature-card-switcher-module--contentContainer--R-USy";
export var dropdown = "feature-card-switcher-module--dropdown--SK5it";
export var dropdownContainer = "feature-card-switcher-module--dropdownContainer--HRgSo";
export var dropdownContainerOpen = "feature-card-switcher-module--dropdownContainer--open--qVrkI";
export var dropdownLink = "feature-card-switcher-module--dropdownLink--zDR0t";
export var icon = "feature-card-switcher-module--icon--kAQIx";
export var iconContainer = "feature-card-switcher-module--iconContainer--NgE8w";
export var iconContainerSelected = "feature-card-switcher-module--iconContainer--selected--4YQq0";
export var iconTitle = "feature-card-switcher-module--iconTitle--Sdb-X";
export var imageContainer = "feature-card-switcher-module--imageContainer--8m81N";
export var mobileContainer = "feature-card-switcher-module--mobileContainer--Pk+Yv";
export var mobileImageContainer = "feature-card-switcher-module--mobileImageContainer--hucrr";
export var navContainer = "feature-card-switcher-module--navContainer--98xdH";
export var relativeContainer = "feature-card-switcher-module--relativeContainer--AVDH2";
export var tabletImage = "feature-card-switcher-module--tabletImage--4aQ3Z";
export var tabletText = "feature-card-switcher-module--tabletText--Z38M3";